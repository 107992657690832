export const classmates = [
  "邓一鸣",
  "梁宇涵",
  "詹子洋",
  "徐佳怡",
  "傅梓航",
  "郭玥婷",
  "周彤宇",
  "李承灏",
  "彭铭均",
  "张皓轩",
  "吕静致",
  "李艺轩",
  "卢尹皓曦",
  "蒙境铭",
  "杜李菲儿",
  "谢佳恩",
  "陈若妍",
  "王若馨",
  "杨诗艺",
  "罗培睿",
  "陈茉",
  "易伍霄苒",
  "彭嘉艺",
  "侯程子",
  "李嘉浩",
  "刘昕玥",
  "王梓鑫",
  "李罗立锦",
  "李丹逸",
  "刘瑾芯",
  "高义洋",
  "高锦浩",
  "张峻豪",
  "孙煜宸",
  "王俊皓",
  "钟秦博",
  "邓懿宸",
  "刘民萱",
  "何禹橙",
  "刘雅楠",
  "赵欣怡",
  "张清昀",
  "杨莞昕",
  "王薇婷",
  "杨沐可",
  "李卓阳",
  "王凡凡",
  "吴忻城",
  "徐佩依",
  "周芷萱",
  "杨涵文",
  "肖锐熙",
  "吴钰凡",
  "谢富山",
  "张浠雅"
];