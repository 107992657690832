import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom'
export const Copyright = (props: any) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      Copyright © <NavLink to="/">www.tt125.cn</NavLink>
      {' '}
      {new Date().getFullYear()}
    </Typography>
  );
}