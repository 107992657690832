import { useState } from "react";
import { TextField, Button } from '@mui/material';
import { Helmet } from "react-helmet"
import { MainFrame } from "../components";
import { classmates } from '../constants'

export const JieLong = (props: any) => {
  const [names, setNames] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (e:any) => {
    setInputValue(e.target.value)
  }
  const handleButtonClick = (e:any) => {
    const newNames:any = classmates.filter((value) => {
      return !inputValue.includes(value);
    });
    setNames(newNames);
  };
  return (
    <MainFrame title="1.1班人员比对工具">
      <Helmet>
        <title>七彩梦中队</title>
      </Helmet> 
      <p>请复制比对内容粘贴到下框中进行排查</p>
      <TextField
        id="filled-multiline-flexible"
        fullWidth
        multiline
        rows={5}
        value={inputValue}
        onChange={handleInputChange}
      />
      <Button variant="contained" size="large" fullWidth onClick={handleButtonClick}>点击查询未在内容里的班级成员</Button>
      <div style={{margin: '16px 0'}}>
        {names.join(',')}
      </div>
    </MainFrame>
  );
}