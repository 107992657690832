import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import { Copyright } from './Copyright';
export const MainFrame = (props: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{flex: 1, width: '100%', padding:'50px 16px'}}>
        {props.children}
      </div>
      <Copyright />
    </Box>
  )
}