import { useState } from 'react'
import { TextField, FormControlLabel, Radio, RadioGroup, Grid, Button } from '@mui/material'
import { MainFrame } from "../components";
export const TestPaper = () => {
  const [values, setValues] = useState({
    min: 10,
    max: 100,
    type: '1',
    count: '50',
  });

  const handleChange = (name:any) => (event:any) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleButtonClick = () => {
    console.log(values)
  }
  return (
    <MainFrame
      title="小学一二年级题单生成器"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField fullWidth label="最小数" value={values.min} onChange={handleChange('min')}></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="最大数" value={values.max} onChange={handleChange('max')}></TextField>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            row
            value={values.type}
            onChange={handleChange('type')}
            name="symbol"
          >
            <FormControlLabel value="1" control={<Radio />} label="加" />
            <FormControlLabel value="2" control={<Radio />} label="减" />
            <FormControlLabel value="3" control={<Radio />} label="混合加减" />
            <FormControlLabel value="4" control={<Radio />} label="连加" />
            <FormControlLabel value="5" control={<Radio />} label="连减" />
            <FormControlLabel value="6" control={<Radio />} label="混合连加连减" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            row
            value={values.count}
            onChange={handleChange('count')}
            name="count"
          >
            <FormControlLabel value={50} control={<Radio />} label="50题" />
            <FormControlLabel value={100} control={<Radio />} label="100题" />
          </RadioGroup>
        </Grid>
      </Grid>
      <Button variant="contained" size="large" fullWidth onClick={handleButtonClick}>生成</Button>
    </MainFrame>
  )
}