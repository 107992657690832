import { Paper, Box, Grid, Typography, Container } from '@mui/material';
import { Copyright } from '../components';


export const Home = () => {
  return (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://static.roland.com/products/vad_series/images/vad506_hero.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>  
          <Box
            sx={{
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100vh',
            }}
          >
            <Box
              sx={{
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}
            >
            <Typography component="h1" variant="h5">
              彤彤(Tony)的个人主页
            </Typography>
            <Box sx={{ mt: 1 }}>
              大家好，我是小鼓手彤彤, 我非常喜欢音乐，喜欢摇滚，喜欢Beyond, 喜欢粤语歌曲，热爱打击乐……
            </Box>
            </Box>
            <Box
              component="footer"
              sx={{
                py: 3,
                mt: 'auto',
              }}
              >
              <Container maxWidth="sm">
                <Copyright />
              </Container>
            </Box>
          </Box>
        </Grid>
      </Grid>
  );
}
