import CssBaseline from '@mui/material/CssBaseline';
import { Routes, Route } from "react-router-dom";
import { Home, JieLong, JieLong202210, TestPaper } from './pages'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/diff" element={<JieLong />} />
        <Route path="/diff202210" element={<JieLong202210 />} />
        <Route path="/test-paper" element={<TestPaper />} />
      </Routes>
    </ThemeProvider>
  );
}
