export const students202210 = [
  "曹柳烨",
  "陈丁楠",
  "陈星羽",
  "仇颎",
  "邓稀月",
  "杜培博",
  "杜忻潼",
  "范彦廷",
  "嘎玛娜熙",
  "高跃嘉",
  "格桑央吉",
  "龚歆雅",
  "郭锦沂",
  "韩雨衡",
  "何恩泽",
  "何诺诗",
  "赖语霏",
  "雷梓萱",
  "李晨宇",
  "李清讴",
  "李轩屹",
  "李宣垚",
  "李雨桐",
  "蔺沐妍",
  "刘博宇",
  "刘宇浩",
  "罗卿凌",
  "彭羿惟",
  "童奕嘉",
  "汪辰熙",
  "王郗娅淇",
  "王馨悦",
  "王彧凡",
  "王智谦",
  "王紫璇",
  "吴董浩然",
  "徐弈典",
  "娅卓",
  "杨小琪",
  "杨依可",
  "杨依琳",
  "杨喻平",
  "易凌菲",
  "袁艺炀",
  "张锦远",
  "张俊琳",
  "张霖瑞",
  "张露尹",
  "张芷琪",
  "钟昊言",
  "周煋柊",
  "朱建淳"
];